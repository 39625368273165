<template>
  <div class="hello">
  <div class="searchBar">
		<input class="inputText" type="text" v-model="keyword">
		<button class="searchButton" @click="searchBtnClick">搜索</button>
	</div>

    <div v-for="(item) in result.goods" :key="item" class="goodsItem" >
      <a :href='item.itemUrl'>
      <img class="goodsImg" :src="item.itemImg"/>
      <div class="goodsName">{{item.itemName}}</div>
      <div class="shopName">{{item.shopName}}</div>
      <div class="infoLabel">
        <p class="goodsPrice">${{item.price}}</p>
        <p class='goodsSoldout'>销量{{item.sold}}</p>
      </div>
      </a>
    </div>
  </div>
</template>


<script >
import axios from 'axios';
import global from "@/global.js"

export default {
  name: 'SearchResult',
  props: {
  },

  mounted(){
	this.keyword= this.$route.query.keyword
    var that = this
    global.getShopList(function(shops){
		that.shopList = shops
		if(that.keyword&&that.keyword.length>0){
			var savedGoods = global.readStorage('SEARCH_'+that.keyword)
			// console.log(savedGoods)
			if(savedGoods){
				that.result.goods = savedGoods
			}else{
				that.searchAll(that.keyword)
			}
		}
	})
	
  },
  methods:{
    searchBtnClick(){
		if(!this.searchButtonEnabled){
			console.log('searchButtonEnabled=false')
			return
		}else{
			this.searchAll(this.keyword)
		}      
    },
    getShopList(){
      var url = 'https://x.adworks.fun/weidianSpider/shop/list'
      axios.get(url).then((res)=>{
        this.shopList = res.data.data
      })
    },
    searchAll(kw){
		this.searchButtonEnabled = false
		this.result.goods = []
		var that = this;

		var url = 'https://x.adworks.fun/weidianSpider/shop/searchGoods?keyword='+kw

		axios.get(url).then((res)=>{
			var itemList = res.data.data
			if(itemList== null || itemList.length == 0){
				alert('没搜到！')
			}else{
				that.result.goods=itemList
				global.saveStorage('SEARCH_'+kw,that.result.goods)
				alert('搜索完成')
			}
			this.searchButtonEnabled = true
			
		})
		
		// var allPromise=[]
		// for(var i=0;i<this.shopList.length;i++){
		// 	var shopId = this.shopList[i].id
		// var shopName = this.shopList[i].name
		// 	allPromise.push(this.search(kw,shopId,shopName))
		// }		
		// Promise.all(allPromise).then(function() {
		// 	global.saveStorage('SEARCH_'+kw,that.result.goods)
		// });

		
    },
    search(kw,shopId,shopName){
      return new Promise((resolve) => {
        var url =`https://thor.weidian.com/decorate/search.itemList/1.0`
        var headers={
          // "referer":"https://h5.weidian.com/",
          // "host":"h5.weidian.com"
        }
        axios.get(url,{
          params:{
            param:`{"shopId":${shopId},"key":"${kw}","offset":0,"limit":20,"from":"h5"}`},
            headers:headers
          }).then((res)=>{
            var itemList = res.data.result.itemList;
            for(var i=0;i<itemList.length;i++){
              var item = itemList[i]
              item.shopName = shopName
              item.shopId = shopId
              this.result.goods.push(item)
            }
            // this.result.goods = this.result.goods.concat(itemList)
            resolve(itemList)
        })
      });
    }
  },
  data(){
    return{
		keyword:"",
		searchButtonEnabled:true,
		shopList:[],
		result:{
			goods:[],
			map:{}
		}
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.hello{
  background: #eee;
}

.searchBar{
    height: 44px;
    background: #eee;
    padding:8px 16px 0px;
}


.inputText{
    float: left;
    height:32px;
    padding: 0px 6px;
    font-size: 12pt;
    width: calc(100% - 72px);
}

.searchButton{
    width: 56px;
    border-width: 0px;
    height:36px;
    background: rgba(0, 0, 0, 0);
    float: left;
    color:#006eff;
    font-size: 12pt;
    text-align: right;
}



.goodsItem{
  display: inline-block;
  position: relative;
  width: 100%;
  height:120px;
  background: #fff;
}


.goodsImg{
  width: 88px;
  height:88px;
  top:16px;
  left:16px;
  border-radius: 6px;
  position: absolute;
}

.goodsName{
  position: absolute;
  font-weight: 600;
  font-size: 14pt;
  left:120px;
  top:16px;  
  height:44px;
  line-height: 22px;
  overflow: hidden;
  color: #333;
  width:calc(100% - 136px);
  text-align: left;

}

.shopName{
  position: absolute;
  font-weight: 400;
  font-size: 10pt;
  left:120px;
  top:60px;  
  height:18px;
  line-height: 18px;
  overflow: hidden;
  color: #999;
  width:calc(100% - 136px);
  text-align: left;
}

.infoLabel{
  position: absolute;
  left:120px;
	bottom:16px;
  height:24px;
  width:70vw;
  width: calc(100% - 136px);
}




.goodsPrice{
  float: left;
  font-weight: 400;
  font-size: 16pt;  
  height:100%;
  color: #f00;
  height: 24px;
  line-height: 24px;
  text-align: left;
  bottom:0;
  position: relative;
  display: inline;
  margin: 0;
  padding: 0;
}




.goodsSoldout{
  float: left;
  font-weight: 400;
  font-size: 10pt;  
  height: 16px;
  line-height: 16px;
  color: #999;
  text-align: left;
  bottom:0;
  position: relative;
  display: inline;
  margin: 6px 0 0 8px;
  padding: 0;
}



</style>
