import axios from 'axios';

export default{

    getDefaultUrl(){
        // return "https://x.adworks.fun/"
        return "http://localhost:3000/"
    },

    getShopList(callback){
        var shopList = this.readStorage("shopList")
        if (shopList && shopList.length>0){
            callback(shopList)
        }else{
            var url = 'https://x.adworks.fun/weidianSpider/shop/list'
            axios.get(url).then((res)=>{
                var shopList = res.data.data
                this.saveStorage("shopList",shopList)
                callback(shopList)
            })
        }
    },

    // saveShopList(shopList){
    //     localStorage.setItem('shopList',JSON.stringify({'time':Date.now(),'shopList':shopList}))
    // },

    // readShopList(){
    //     console.log('readShopList')
    //     var savedJsonString = localStorage.getItem('shopList')
    //     if(savedJsonString){
    //         console.log('savedJsonString')
    //         var json = JSON.parse(savedJsonString)
    //         if((Date.now() - json.time)<60*60*1000){
    //             return json.shopList;
    //         }
    //     }
    //     return null;
    // },


    saveStorage(key,obj){
        console.log('save '+key)
        localStorage.setItem(key,JSON.stringify({'time':Date.now(),"data":obj}))
    },

    readStorage(key){
        console.log('read '+key)
        var savedJsonString = localStorage.getItem(key)
        if(savedJsonString){
            var obj = JSON.parse(savedJsonString)
            if((Date.now() - obj.time)<60*60*1000){
                return obj.data
            }
        }
        return null;
    },

}