<template>
	<div class="hello">
	<div v-if="userId==null">
		<input class="editText" type="text" v-model="phone" placeholder="请输入手机号">
		<button class="loginButton" @click="login">登录</button>
	</div>
	<div v-if="userId!=null && ordering">
		<span>下单收货地址为账户默认地址</span>
		<br>
		<span>使用新地址请填写第三行</span>
		<input class="editText" type="text" v-model="formLink" placeholder="此处填写商品链接">
		<input class="editText" type="text" v-model="formComment" placeholder="此处填写SKU信息">
		<input class="editText" type="text" v-model="formAddr" placeholder="若使用非默认地址请在此补充">
		<button class="loginButton" @click="submit">提交</button>
	</div>


	<div v-if="userId!=null && !ordering">	
		<div class='topBar'>
			
			<div @click="logout">
				<img class="avatarIcon" src='../assets/icon-user.png'/>
				<div class="phoneLabel">{{userInfo.phone}}</div>
			</div>
			
			<div @click="changeCurrentStatus">
				<div class="filterLabel">{{getStatusFilterDesc(currentStatus)}}</div>
				<img class="filterIcon" src='../assets/icon-filter.png'/>
			</div>
		</div>

		
		<img class="createButton" src='../assets/icon-add.png' @click="gotoCreateOrderPage">
		
		<div v-for="(order) in showOrderList" :key="order.id" class="orderItem" >
			
			
			<a class='shopName' :href="'https://shop'+order.shop_id+'.v.weidian.com/?userid='+order.shop_id">{{order.shop_name}} ></a>
			<div class='orderStatus'>{{getStatusDesc(order.status)}}</div>
			

			
			<a class="goodsItem" :href="order.link">
				<img class="goodsImg" :src="order.item_img_url"/>
				<div class="goodsName">{{order.item_name}}</div>
				<div class="goodsSKU">{{order.comment}}</div>
				<div class="orderAddr">{{order.addr}}</div>
			</a>
			

			<div class='priceLabel'>原价 {{order.item_price}}元   代购价 {{(order.item_price*userInfo.discount).toFixed(2)}}元</div>
			<div class='orderActionBar'>
			<div v-if='order.status==0' class='orderActionButton' @click='changeOrderStatus(order.id, -1,"取消订单")'>取消订单</div>
			<div v-if='order.status==1 || order.status==2' class='orderActionButton' @click='changeOrderStatus(order.id,4,"确认收货")' >确认收货</div>
			<div v-if='order.status==4' class='orderActionButton' @click='changeOrderStatus(order.id, -1,"删除订单")'>删除订单</div>
			</div>
		</div>
	</div>
	</div>


</template>


<script >
import axios from 'axios';
import global from "@/global.js"

export default {
	name: 'OrderList',
	props: {
	},

	unmounted() { // 销毁vm组件
	// 避免堆栈溢出，多次创建、多次触发
		window.removeEventListener('popstate', this.popstate, false);
	},

	mounted(){
	// var that = this
		window.addEventListener('popstate', this.popstate, false);
		this.userId = localStorage.getItem('userId')
		if(this.userId){
			this.getUserInfo()
			this.getOrderList()
		}
	},
	methods:{
		popstate(e){
			console.log(e)
			console.log('popstate')
			if(this.ordering == true){
				this.ordering = false
			}
		},

		login(){
			var that = this
			var url = global.getDefaultUrl()+'shopping/user/login'
			axios.post(url,{"phone":this.phone}).then((res)=>{
				if(res.data.code==0){
					// console.log(session.userId)
					that.userInfo = res.data.data
					that.userId = res.data.data.id
					localStorage.setItem('userId',that.userId)
					that.getOrderList()
				}
			})
		},

		logout(){
			var that = this
			if(window.confirm('是否要登出帐号？')){
				localStorage.removeItem('userId')
				that.userId=null
				that.userInfo={}
			}
		},



		gotoCreateOrderPage(){
			// window.history.pushState('ordering', "title", "#/orderList/ordering"); 
			this.ordering = true
		},

	getUserInfo(){
		var that = this
		var url = global.getDefaultUrl()+'shopping/user/info?id='+this.userId
		axios.get(url).then((res)=>{
			that.userInfo = res.data.data
		})
	},


	getOrderList(){
		var that = this
		var url = global.getDefaultUrl()+'shopping/order/list?userId='+this.userId
		axios.get(url).then((res)=>{
		that.orderList = res.data.data
		that.selectFilter(that.currentStatus)
		})
	},

	selectFilter(status){
		this.currentStatus = status
		
		if(status == 100){
			this.showOrderList = this.orderList
			return
		}
		this.showOrderList=[]
		for(var i=0;i<this.orderList.length;i++){
			if(this.orderList[i].status == status){
				this.showOrderList.append(this.orderList[i])
			}
		}
	},

	getStatusDesc(status){
		switch(status){
		case 0:
			return '待下单'
		case 1:
			return '已下单'
		case 2:
			return '已发货'
		case 3:
			return '已签收'
		case 4:
			return '已结算'
		default:
			return '失效'
		}
	},

	getStatusFilterDesc(status){
		switch(status){
		case 0:
			return '待下单'
		case 1:
			return '已下单'
		case 2:
			return '已发货'
		case 3:
			return '已签收'
		case 4:
			return '已结算'
		default:
			return '全部订单'
		}
	},

	changeOrderStatus(orderId,status,msg){
		// console.log('msg')
		var that = this
		if(window.confirm('是否要'+msg+'？')){
			var url = global.getDefaultUrl()+'shopping/order/update'
			axios.post(url,{"id":orderId,"status":status}).then(()=>{
				that.getOrderList()
			})
		}
	},

	submit(){
		if(this.formLink.length == 0 || this.formComment.length == 0){
			alert('请填写商品链接和规格信息')
			return
		}
		this.createOrder(this.formLink,this.formComment,this.formAddr)
	},

	createOrder(link,comment,addr){
		var that = this
		var url = global.getDefaultUrl()+'shopping/order/create'
		var data={
		"userId":this.userId,
		"link":link,
		"comment":comment,
		"addr":addr,
		}
		axios.post(url,data).then((res)=>{
			if(res.data.code ==0){
				that.ordering = false
				that.getOrderList()
				if(res.data.msg.length>0){
					alert(res.data.msg)
				}
			}else{
				alert('提交失败，请检查链接')
			}
		})
	},
	
	changeCurrentStatus(){
		switch(this.currentStatus){
			case 0:
				this.currentStatus = 1
				break
			case 1:
			case 2:
				this.currentStatus = 3
				break
			case 3:
				this.currentStatus = 4
				break
			case 4:
				this.currentStatus = 100
				break
			case 100:
				this.currentStatus = 1
				break
			default:
				this.currentStatus = 100
				break

		}

		this.selectFilter(this.currentStatus)
	}

},
	data(){
		return{
			phone:"",
			ordering:false,
			orderList:[],
			showOrderList:[],
			currentStatus:100,
			userId:null,
			userInfo:{},
			formLink:'',
			formComment:'',
			formAddr:''
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.hello{
	padding-top:44px;
	height: 100%;
	background: #efefef;
}

.topBar{
	position: fixed;
	top:0;
	left:0;
	padding:6px 12px;
	display: inline-block;
	background: white;
	height:32px;
	width: calc(100% - 24px);
	z-index: 9999;
	
}

.avatarIcon{
	width: 32px;
	height: 32px;
	float: left;
	
}

.filterIcon{
	width: 32px;
	height: 32px;
	float: right;
}

.phoneLabel{
	margin-left: 4px;
	font-size: 12pt;
	float: left;
	width: 100px;
	line-height: 32px;
}

.filterLabel{
	font-size: 12pt;
	float: right;
	width: 64px;
	line-height: 32px;
}

.createButton{
	width: 88px;
	height: 88px;
	position: fixed;
	bottom:80px;
	right:20px;
	z-index:999;
}




.editText{
	margin:20px 0 0;
	height:44px;
	font-size: 16pt;
	padding-left: 8px;
	width: calc(100% - 80px);
}

.loginButton{
	width: calc(100% - 64px);
	margin-top: 20px;
	border-width: 1px;
	border-radius: 4px;
	border-color: lightgray;
	height:52px;
	font-size: 16pt;
	text-align: center;
}



.orderItem{
  padding:12px 0;
  display: inline-block;
  margin-top: 16px;
  width: 100%;
  height:212px;
  background: #fff;
}




.shopName{
  position: absolute;
  font-weight: 400;
  font-size: 14pt;
  left:16px;
  line-height: 24px;
  height: 24px;
  overflow: hidden;
  color: #333;
  width:calc(100% - 120px);
  text-align: left;
}

.orderStatus{
  position: absolute;
  font-weight: 400;
  font-size: 14pt;
  right:16px;
  line-height: 24px;
  height: 24px;
  overflow: hidden;
  color: #666;
  width:80px;
  text-align: right;  
}


.goodsItem{
  position: relative;
  display: inline-block;
  margin-top: 32px;
  background: #eee;
  width: 100%;
  height:96px;
}

.goodsImg{
  width: 64px;
  height:64px;
  top:16px;
  left:16px;
  border-radius: 4px;
  position: absolute;
}

.goodsName{
  position: absolute;
  font-weight: 600;
  font-size: 12pt;
  left:96px;
  top:16px;  
  height:22px;
  line-height: 22px;
  overflow: hidden;
  color: #333;
  width:calc(100% - 136px);
  text-align: left;
}

.goodsSKU{
  position: absolute;
  font-weight: 400;
  font-size: 12pt;
  left:96px;
  top:38px;  
  height:22px;
  line-height: 22px;
  overflow: hidden;
  color: #666;
  width:calc(100% - 136px);
  text-align: left;
}

.orderAddr{
  position: absolute;
  font-weight: 400;
  font-size: 12pt;
  left:96px;
  top:60px;  
  height:22px;
  line-height: 22px;
  overflow: hidden;
  color: #666;
  width:calc(100% - 136px);
  text-align: left;
}


.priceLabel{
  font-weight: 400;
  margin:0px 16px 0 ;
  color: #333;
  font-size: 13pt;
  width:calc(100% - 32px);
  text-align: right;
}


.orderActionBar{
  margin-top: 12px;
  height:40px;
}

.orderActionButton{
  float: right;
  margin: 0 8px ;
  font-size: 13pt;
  width: 72px;
  padding:6px 8px;
  border-style:solid;
  border-color: #999;
  border-radius: 4px;
  border-width: 1px;
  height:24px;
}



</style>
