<!-- eslint-disable no-unused-vars -->
<template>
  <div class="hello">
    <!-- <div v-for="(item) in shopList" :key="item" class="shopItem" >
      <a :href='item.link'>
      <img class="shopImg" mode="aspectFill" :src="item.img"/>
      <div class="shopBottom">
        <img class="shopLogo" :src="item.logo"/>
        <div class="shopName">{{item.name}}</div>
      </div>
      </a>
    </div> -->
    <div v-if="showTips" class="notice">
        <span class='noticeText'>代下单须知</span>
        <span class='noticeText'>1. 勿直接下单，商品加购后截图发我，我代下单。</span>
        <span class='noticeText'>2. 仅支持此列表中的店铺内原价不超过2000元的商品。</span>
        <span class='noticeText'>3. 退换货规则与各店铺一致，下单前请核实店铺规则。</span>
        <div class='iknowButton' @click="iknow">我知道了</div>
    </div>
    <!-- <div v-if="!showTips && shopList.length>0" class="searchBar">
      <input class="inputText" type="text" v-model="keyword">
      <button class="searchButton" @click="searchBtnClick">搜索</button>
    </div> -->
    <div v-for="(item) in shopList" :key="item" class="shopItem" >
      <a :href='item.link'>
      <img class="shopLogo" :src="item.logo"/>
      <div class="shopName">{{item.name}}</div>
      <div class="shopCredit">
        <img class='creditIcon' v-for="index in item.credit_num" :key="index" :src="require(`../assets/${item.credit_type}.png`)" >
        <div v-if="item.soldout>0" class='shopSoldout'>销量{{item.soldout}}</div>
      </div>
      <div class="shopNote">{{item.note}}</div>
      </a>
    </div>
    <div style="height:60px"></div>
  </div>
</template>


<script >
// import axios from 'axios';
import global from "@/global.js"
export default {
  name: 'WeidianShop',
  props: {

  },

  mounted(){
    var iknow = localStorage.getItem('iknow');
    if(iknow){
      this.showTips = false
    }
	var that = this
    global.getShopList(function(shops){
      that.shopList = shops
    })
  },
  methods:{
    searchBtnClick(){
		if(this.keyword.length>0){
			this.$router.push({ path: '/search', query: { keyword: this.keyword } })
		}
    },
    iknow(){
      this.showTips = false;
      localStorage.setItem('iknow',true);
    }

  },
  data(){
    return{
      keyword:"",
      shopList:[],
      showTips:true
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hello{
  background: #eee;
}


.notice{
  position: sticky;
  width: calc(100% - 32px);
  padding:12px 16px;
  top:0;
  height:110px;
  background: #ffd6d6;
  z-index: 999;
  overflow: hidden;
}

.noticeText{
  float: left;
  width: 100%;
  line-height: 18px;
  margin-top:2px;
  color:#FF4F4F;
  font-size: 10pt;
  text-align: left;
}

.iknowButton{
  float: right;
  width: 100%;
  line-height: 18px;
  margin-top:2px;
  color:#006eff;
  font-size: 10pt;
  text-align: right;
  padding:6px 0;
}

.searchBar{
    height: 44px;
    background: #eee;
    padding:8px 16px 0px;
}


.inputText{
    float: left;
    height:32px;
    padding: 0px 6px;
    font-size: 12pt;
    border-width: 0px;
	border-radius: 4px;
    width: calc(100% - 72px);
}

.searchButton{
    width: 56px;
    border-width: 0px;
    height:36px;
    background: rgba(0, 0, 0, 0);
    float: left;
    color:#006eff;
    font-size: 12pt;
    text-align: right;
}


.shopItem{
  display: inline-block;
  position: relative;
  width: 100%;
  height:120px;
  background: #fff;
}


.shopLogo{
  width: 88px;
  height:88px;
  top:16px;
  left:16px;
  border-radius: 6px;
  position: absolute;
}

.shopName{
  position: absolute;
  font-weight: 600;
  font-size: 14pt;
  left:120px;
  top:16px;  
  height:24px;
  overflow: hidden;
  color: #333;
  width:calc(100% - 136px);
  text-align: left;

}

.shopCredit{
  position: absolute;
  left:120px;
  top:44px;  
  height:20px;
  width:70vw;
  width: calc(100% - 136px);
}

.creditIcon{
  float: left;
  display: inline;
  width: 16px;
  height: 16px;
  margin-top: 1px;
  margin-right: 3px;
}

.shopNote{
  position: absolute;
  font-weight: 400;
  font-size: 11pt;
  left:120px;
  bottom:16px;  
  height:36px;
  line-height: 18px;
  color: #666;
  width: calc(100% - 136px);
  text-align: left;
  overflow: hidden;
  
}



.shopSoldout{
  float: left;
  margin-left: 8px;
  font-weight: 400;
  font-size: 10pt;  
  height:100%;
  color: #999;
  text-align: left;
}




/* .shopItem{
  display: inline-block;
  margin-top:20px;
  border-radius: 5px;
  position: relative;
  padding:0 4vw;
  border: #000;
  width: 92vw;
  height:47vw;
}

.shopImg{
  width: 100%;
  height:100%;
  border: #840;
  border-width: 2px;
}

.shopBottom{
  position: absolute;
  bottom: 0vw;
  width: 92vw;
  height:36px;
  background: rgba(0, 0, 0, 0.7);

}

.shopLogo{
  left:8px;
  bottom:6px;
  position: absolute;
  height:24px;
  width:24px;
  border-radius: 12px;
  background: #fff;
}

.shopName{
  position: absolute;
  font-weight: 600;
  left:44px;
  bottom:8px;  
  height:20px;
  width:20px;
  color: #fff;
  width:70vw;
  text-align: left;

} */


</style>
