import { createApp } from 'vue'
import { createRouter,createWebHashHistory } from 'vue-router'
import App from './App.vue'
import WeidianShop from './components/WeidianShop.vue'
import SearchResult from './components/SearchResult.vue'
import OrderList from './components/OrderList.vue'

const routes = [
    { path: '/', component: WeidianShop,meta: {title: '微店代下单'}},
    { path: '/search', component: SearchResult,meta: {title: '搜索结果'} },
    { path: '/orderList', component: OrderList,meta: {title: '我的订单'} },
]
 

const router = createRouter({
    history: createWebHashHistory(),
    routes, // `routes: routes` 的缩写
})

router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
        document.title = to.meta.title
    }
    next()
})
 
createApp(App).use(router).mount('#app')
